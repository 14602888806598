import axios from 'axios';
import qs from 'qs';
import { Message } from 'view-design';// 我用的mint的框架来弹出我的错误返回 大家可以用别的提示
// import router from './router'

// 默认超时设置
axios.defaults.timeout = 50000;

// 相对路径设置
axios.defaults.baseURL = '';

//http request 拦截器
axios.interceptors.request.use(
    config => {
        // 获取token
        const token = localStorage.getItem('alex_token');
        // 设置参数格式
        if (!config.headers['Content-Type']) {
            config.headers = {
                'Content-Type': 'application/json',
            };
        }
        // 添加token到headers
        if (token) {
            config.headers.token = token
        }
        // 鉴权参数设置
        if (config.method === 'get') {
            //get请求下 参数在params中，其他请求在data中
            config.params = config.params || {};
            // let json = JSON.parse(JSON.stringify(config.params));
            //一些参数处理
        }else if (config.method === 'post') {
            config.headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
            };
        } else {
            config.data = config.data || {};
            //一些参数处理
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
    response => {
        //一些统一code的返回处理
        if (response.data.code === 501) {
            // 登录验证
            //做了个示例跳转项目中登录，并记录下相对路径
            // router.push({
            //     name: 'login',
            //     query: {
            //         retUrl: window.location.href.split('#')[1] || '',
            //         is_new_user_url: 1
            //     }
            // })
        }
        return response;
    },
    error => {
        return Promise.reject(error)
    }
);

/**
 * 封装get post put del 方法
 * @param url
 * @param params/data
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(response => {
                if (response.data.code !== 0) {
                    resolve(response.data);
                } else {
                    //错误处理
                    Message.error(response.data.msg)
                }
            })
            .catch(err => {
                reject(err);
                let message = '请求失败！请检查网络';
                //错误返回
                if (err.response) message = err.response.data.message;
                Message.error(message)
            })
    })
}


export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url,qs.stringify(data))
            .then(response => {
                if (response.data.code !== 0) {
                    resolve(response.data);
                } else {
                    Message.error(response.data.msg)
                }
            }, err => {
                reject(err);
                let message = '请求失败！请检查网络';
                if (err.response) message = err.response.data.message;
                Message.error(message)
            })
    })
}


export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                if (response.data.code !== 0) {
                    resolve(response.data);
                } else {
                    Message.error(response.data.msg)
                }
            }, err => {
                reject(err);
                let message = '请求失败！请检查网络';
                if (err.response) message = err.response.data.message;
                Message.error(message)
            })
    })
}

export function del(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(url, data)
            .then(response => {
                if (response.data.code === 0) {
                    resolve(response.data);
                } else {
                    Message.error(response.data.msg)
                }
            }, err => {
                reject(err);
                let message = '请求失败！请检查网络';
                if (err.response) message = err.response.data.message;
                Message.error(message)
            })
    })
}