import { post } from '../config/request'

const baseUrl = 'https://www.dajinlogistic.com.cn';
//详情
export const getOrder = function (data) {
    return post(baseUrl + "/trace_new/order_traceinfo.php", data)
};
//列表
export const getOrderList = function (data) {
    return post(baseUrl + "/trace_new/orderlist_search.php", data)
};
//下载
export const download = function (data) {
    return post(baseUrl + "/sapi/download.php", data)
};