<template>
  <div class="view">
    <div class="head">
      <div>大金物流 | 网站首页</div>
      <div class="num">咨询电话 : 021-34306999</div>
    </div>
    <div class="form">
      <img src="../assets/1.jpg" alt="">
      <div class="bg"></div>
    </div>
    <div class="box">
      <div class="title">物流跟踪信息查询</div>
      <div class="input">
        <i-input size="large" v-model="value" @on-enter="search">
          <i-button @click="search" slot="append" type="primary" style="margin: -7px -8px;height: 41px;background: #143091;color: #fff;">查询</i-button>
        </i-input>
      </div>
    </div>

    
    <Row type="flex" justify="center">
      <i-col span="18">
        <Table border :columns="columns" :data="list">
          <template slot-scope="{ row }" slot="ORDER_NUMBER">
            <strong>{{ row.ORDER_NUMBER }}</strong>
          </template>
          <template slot-scope="{ row }" slot="ORDER_STATE">
            <strong v-if="row.ORDER_STATE == 1">已接单</strong>
            <strong v-else-if="row.ORDER_STATE == 8">已签收</strong>
            <strong v-else>在途</strong>
          </template>
          <template slot-scope="{ row }" slot="action">
              <Button type="primary" size="large" style="margin-right: 5px" @click="detail(row.TRACKING_NUMBER);modal=true">点击查看</Button>
              <!-- <Button v-if="row.pic_url != ''" type="warning" size="large" style="margin-top: 10px" @click="see(row.pic_url)">查看回单</Button> -->
          </template>
        </Table>
      </i-col>
    </Row>

    <Modal
      v-model="modal"
      width="70%"
      title="物流详情"
      :footer-hide="true"
      :scroll-wheel-zoom="true">
      <Row type="flex" justify="center" class="table" v-if="data_arr != null">
        <i-col push="1" span="6" class="info">
          <div><span>起运地：</span>{{data_arr.start_city}}</div>
          <div><span>目的地：</span>{{data_arr.to_city}}</div>
          <div><span>发货日期：</span>{{data_arr.ship_date}}</div>
          <div><span>预期到货：</span>{{data_arr.est_date}}</div>
          <div><span>件数：</span>{{data_arr.pack_num}}</div>
          <div><span>重量：</span>{{data_arr.wei}}</div>
          <div><span>体积：</span>{{data_arr.volu}}</div>
          <div><span>客服：</span>{{data_arr.cust}}</div>
          <div><span>联系电话：</span>{{data_arr.tel}}</div>
          <div><span>运输状态：</span>{{data_arr.state}}</div>
          <div v-if="data_arr.carr_number != ''"><span>快递单号：</span>{{data_arr.carr_number}}</div>
        </i-col>
        <i-col push="4" span="9" v-if="data_arr.trace != null">
          <Timeline class="center">
              <Timeline-item :color="index == 0 ? 'green':'blue'" v-for="(item,index) in data_arr.trace" :key="index">
                  <p class="time">{{item.dates}}</p>
                  <p class="content">{{item.infos}}</p>
                  <p class="content">{{item.address}}</p>
              </Timeline-item>
          </Timeline>
        </i-col>
      </Row>
      <Row type="flex" justify="end" class="table" v-if="data_arr != null">
        <i-col span="3" v-if="data_arr.pic_url != '' && data_arr.pic_url != null"><Button type="warning" size="large" @click="see(data_arr)">查看回单</Button></i-col>
        <i-col span="3" v-if="map_end.lng != null"><Button type="success" size="large" @click="mapState=true">地图监控</Button></i-col>
        <i-col span="3" v-if="data_arr.item.length != 0"><Button type="primary" size="large" @click="itemState=true">查看产品</Button></i-col>
      </Row>
    </Modal>
    <Modal
      v-model="mapState"
      width="50%"
      :styles="{top: '30px'}"
      title="地图监控"
      :footer-hide="true">
      <iframe v-if="mapState" :src="'https://image.findeng.com/Static/public/mini_v3/demo/demo.html?path='+map_waypoints" name="showHere" class="map"></iframe>
    </Modal>

    <Modal
      v-model="itemState"
      width="80%"
      :styles="{top: '30px'}"
      title="产品信息"
      :footer-hide="true">

      <Table border :columns="data_item" :data="data_arr.item" v-if="data_arr != null"></Table>
    </Modal>

    <Modal
      v-model="phoneState"
      width="400px"
      :styles="{top: '30px'}"
      title="请输入发货方或收货方联系电话的后四位"
      @on-ok="seeImg">
        <div style="text-align: center;">
          <Input size="large" v-model="phone" placeholder="请输入后四位" style="width: 300px;font-size:30px;" />
        </div>
      
    </Modal>

    <a href="https://beian.miit.gov.cn" target="_blank" class="bottom_a">泸ICP备05042030号-2</a>
  </div>
</template>

<script>
import { getOrderList, getOrder, download } from "../api/index";
export default {
  name: "search",
  data() {
    return {
      map: null,
      value: "",
      num: "",
      list: [],
      data_arr: null,
      modal: false,
      itemState: false,
      phoneState: false,
      mapState: false,
      data_item: [
        {
          title: "行号",
          key: "line_number"
        },
        {
          title: "产品",
          key: "item_number"
        },
        {
          title: "描述",
          key: "item_name"
        },
        {
          title: "批号",
          key: "lot_number"
        },
        {
          title: "数量",
          key: "shipped_qty"
        },
        {
          title: "单位",
          key: "uom"
        }
      ],
      columns: [
        {
          title: "订单号",
          slot: "ORDER_NUMBER"
        },
        {
          title: "发货日期",
          key: "SHIP_DATE"
        },
        {
          title: "预计到货日期",
          key: "EST_DATE"
        },
        {
          title: "运单号",
          key: "TRACKING_NUMBER"
        },
        {
          title: "状态",
          slot: "ORDER_STATE"
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center"
        }
      ],
      map_start: {},
      map_end: {},
      map_waypoints: [],
      phone: "",
      imgUrl: ""
    };
  },
  mounted() {
    // console.log(this.$store.state.text);
    // getList().then(res => {
    //   console.log(res);
    // });
    // this.search()
    // this.detail();
    if (this.$route.query.num.length > 0) {
      this.value = this.$route.query.num;
      this.search();
    }
  },
  // order_number tracking_number
  methods: {
    car() {
      var driving = new AMap.Driving({
        map: this.map,
        panel: "panel"
      });
      // 根据起终点经纬度规划驾车导航路线
      driving.search(
        new AMap.LngLat(116.379028, 39.865042),
        new AMap.LngLat(116.427281, 39.903719),
        function(status, result) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          // if (status === "complete") {
          //   log.success("绘制驾车路线完成");
          // } else {
          //   log.error("获取驾车数据失败：" + result);
          // }
        }
      );
      this.mapState = true;
    },
    search() {
      this.list = [];
      this.data_arr = null;
      this.map_start = {};
      this.map_end = {};
      this.map_waypoints = [];
      getOrderList({ order_number: this.value }).then(res => {
        this.list = res;
      });
    },
    detail(num) {
      this.data_arr = null;
      this.map_start = {};
      this.map_end = {};
      this.map_waypoints = [];
      getOrder({ tracking_number: num }).then(res => {
        this.data_arr = res.data;
        this.mapInfo(res.data);
      });
    },
    mapInfo(opt) {
      opt.trace.reverse();
      let data = opt;
      this.map_start = {
        lng: data.trace[data.trace.length - 1].jd,
        lat: data.trace[data.trace.length - 1].wd
      };
      if (data.trace[0].infos == "回单") {
        this.map_end = {
          lng: data.trace[1].jd,
          lat: data.trace[1].wd
        };
      } else {
        this.map_end = {
          lng: data.trace[0].jd,
          lat: data.trace[0].wd
        };
      }
      this.map_waypoints = [];
      data.trace.forEach((item, i) => {
        if (item.jd != "" && item.infos != "回单") {
          this.map_waypoints.push({
            lng: item.jd,
            lat: item.wd,
            time: item.dates,
            text: item.infos
          });
        }
      });
      this.map_waypoints = JSON.stringify(this.map_waypoints);
      opt.trace.reverse();
    },
    see(data) {
      this.imgUrl = this.decode(data.pic_url);
      this.phoneData = data.pic_phone;
      this.phoneState = true;
    },
    seeImg() {
      let a = 0;
      this.phoneData.map((item, i) => {
        if (this.decode(item) == this.phone) {
          if (this.imgUrl.substring(this.imgUrl.length - 3) == "jpg") {
            window.open(this.imgUrl, "_blank").location;
          } else {
            download({ pic_url: this.imgUrl }).then(res => {
              window.open(
                "https://www.dajinlogistic.com.cn/SAPI/" + res.msg,
                "_blank"
              ).location;
            });
          }
        } else {
          a++;
        }
      });
      if (a == this.phoneData.length) {
        this.$Message.error("验证错误，请重新输入");
        this.phone = "";
      }
    },
    decode(input) {
      var _keyStr =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
      var output = "";
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;
      var re = /[^A-Za-z0-9\+\/\=]/g;
      input = input.replace(re, "");
      while (i < input.length) {
        enc1 = _keyStr.indexOf(input.charAt(i++));
        enc2 = _keyStr.indexOf(input.charAt(i++));
        enc3 = _keyStr.indexOf(input.charAt(i++));
        enc4 = _keyStr.indexOf(input.charAt(i++));
        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;
        output = output + String.fromCharCode(chr1);
        if (enc3 !== 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 !== 64) {
          output = output + String.fromCharCode(chr3);
        }
      }
      return output;
    }
  }
};
</script>

<style scoped lang="less">
.map {
  width: 100%;
  height: 600px;
  border: 0;
}
.view {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}
.head {
  background: #143091;
  color: #fff;
  display: flex;
  font-size: 18px;
  padding: 20px;
  position: relative;
  .num {
    position: absolute;
    right: 20px;
  }
}
.form {
  width: 100%;
  position: relative;
  height: 400px;
  overflow: hidden;
  img {
    width: 100%;
    position: relative;
    top: -60px;
  }
  .bg {
    background: linear-gradient(to left, rgba(45, 120, 244, 0), #143091);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
  }
}
.box {
  width: 100%;
  height: 100%;
  color: #143091;
  font-weight: bold;
  font-size: 32px;
  margin-top: 30px;
  .input {
    width: 40%;
    margin: 10px auto;
  }
  .text {
    padding: 10px;
  }
}
.table {
  margin: 30px 0;
}
.info {
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  div {
    flex: 0 0 100%;
    display: flex;
  }
  span {
    display: block;
    min-width: 100px;
    text-align: left;
  }
}
.center {
  text-align: left;
}
.table_flex {
  display: flex;
  flex-wrap: wrap;
  .box {
    flex: 0 0 31%;
    padding: 10px;
    margin: 0 1%;
    margin-bottom: 15px;
    background: #f1f1f1;
    border-radius: 10px;
    div {
      font-size: 14px;
    }
  }
}
.bottom_a{
  position: fixed;
  left:0;
  right:0;
  margin: 10px auto;
  bottom:10px;
}
</style>
